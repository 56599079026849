exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aftercare-js": () => import("./../../../src/pages/aftercare.js" /* webpackChunkName: "component---src-pages-aftercare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-margs-js": () => import("./../../../src/pages/margs.js" /* webpackChunkName: "component---src-pages-margs-js" */),
  "component---src-pages-mt-lawley-js": () => import("./../../../src/pages/mt-lawley.js" /* webpackChunkName: "component---src-pages-mt-lawley-js" */),
  "component---src-pages-perth-js": () => import("./../../../src/pages/perth.js" /* webpackChunkName: "component---src-pages-perth-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

